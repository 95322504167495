<template>
  <a-config-provider :locale="locale" :theme="{
      token: {
        colorPrimary: '#1677ff',
      },
    }">
    <router-view />
  </a-config-provider>
</template>

<script>
// import zhCN from "ant-design-vue/es/locale/zh_CN"; // 引入antd中文包
import { defineComponent } from "vue"; // 引入onMounted
// import moment from "moment"; // 引入moment
// moment.locale("zh-cn"); // 配置moment中文环境

export default defineComponent({
  name: "App",
  components: {},
  data() {
    return {
      // locale: zhCN, // 传值给a-config-provider组件
      date: "",
    };
  },
  mounted() {
    // 百度统计代码
    var _hmt = _hmt || [];
    (function() {
      var hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?c6a6e5beabf9e50864efb3de26072afa";
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    })();
  }
});
</script>
<style lang="scss">
body {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  outline: none;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC,
    Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  // font-family: Microsoft YaHei, BlinkMacSystemFont, Segoe UI, PingFang SC,
  //   Hiragino Sans GB, Helvetica Neue, Helvetica, Arial,
  //   sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}
</style>
