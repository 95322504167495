<template>
  <a-layout style="min-height: 100vh">
    <a-layout>
      <a-layout-content style="background: #fff; padding: 50px 0 20px 0">
        <a-flex style="height: 100%;" gap="large" justify="flex-center" align="center" vertical>

          <!--logo-->
          <a-image style="padding-bottom: 0px; filter: blur(0px);" :preview="false" :width="80" src="/logo.png" />

          <!-- logo 下面显示一行文字 -->
          <div style="text-align: center; margin: 0; padding-bottom: 20px;">
            <h1 style="color: rgba(0, 0, 0, .7); font-size: 16px; white-space: nowrap; margin: 0; font-weight: 310;">｛ MiYo.AI ｝ 米柚AI - 让信息探索更简单</h1>
          </div>


          <a-flex style="padding: 0 10px;" gap="large" justify="flex-end" align="center" vertical>

          <a-flex class="search-box" gap="large" justify="flex-end"  vertical>
            <!--搜索框-->
            <div class="search-box-textarea">
              <a-textarea style="font-size: 14px;" @keydown="TAKeyDownEvent" @change="TAChangeEvent" v-model:value="Message" :bordered="false"
                placeholder="请输入问题，Enter键发送，Shift+Enter键换行" :rows="3" size="large"
                :auto-size="{ minRows: 3, maxRows: 3 }" />
            </div>

            <!--搜索范围-->
            <a-flex style="padding: 0 10px 5px 5px;" justify="space-between" align="center" horizontal>
              <a-flex flxe="1" justify="flex-start">
              <a-dropdown arrow :trigger="['click']">
                <a-tooltip title="设置搜索范围" :overlayStyle="{ fontSize: '12px' }">
                  <a-button size="middle" shape="round" type="text" style="display: flex; align-items: center;">
                    <SearchOutlined style="font-size: 15px; color: rgba(0, 0, 0, .6);" />
                    <span style="font-size: 12px; padding: 0px; color: rgba(0, 0, 0, .6);">{{selectedItem.text}}</span>
                  </a-button>
               </a-tooltip>
                <template #overlay>
                  <a-menu @click="onMenuClick">
                    <p style="padding: 0 15px 0; font-size: 12px; font-weight: 700; line-height: 18px; text-align: left; color: #98a2b3;">
                      搜索范围</p>
                    <a-menu-item key="1">
                      <a-space size="small">
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="6" cy="6" r="4.5" fill="#F79009" stroke="#FEF0C7" stroke-width="3"></circle>
                        </svg>
                        <template v-if="currentKey == 1">
                          <a style="color: #1570ef; font-size: 12px;" href="javascript:;">全网</a>
                          <CheckOutlined style="color: #1570ef;" />
                        </template>
                        <template v-else>
                          <a style="color: #000; font-size: 12px;" href="javascript:;">全网</a>
                        </template>
                      </a-space>
                    </a-menu-item>
                    <a-menu-item key="2">
                      <a-space size="small">
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="6" cy="6" r="4.5" fill="#2E90FA" stroke="#D1E9FF" stroke-width="3"></circle>
                        </svg>
                        <template v-if="currentKey == 2">
                          <a style="color: #1570ef; font-size: 12px;" href="javascript:;">谷歌</a>
                          <CheckOutlined style="color: #1570ef;" />
                        </template>
                        <template v-else>
                          <a style="color: #000; font-size: 12px;" href="javascript:;">谷歌</a>
                        </template>
                      </a-space>
                    </a-menu-item>
                    <a-menu-item key="3">
                      <a-space size="small">
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="6" cy="6" r="4.5" fill="#28A745" stroke="#D1E9FF" stroke-width="3"></circle>
                        </svg>
                        <template v-if="currentKey == 3">
                          <a style="color: #1570ef; font-size: 12px;" href="javascript:;">必应</a>
                          <CheckOutlined style="color: #1570ef;" />
                        </template>
                        <template v-else>
                          <a style="color: #000; font-size: 12px;" href="javascript:;">必应</a>
                        </template>
                      </a-space>
                    </a-menu-item>
                    <a-menu-item key="4">
                      <a-space size="small">
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="6" cy="6" r="4.5" fill="#cb1c1d" stroke="#fae8e9" stroke-width="3"></circle>
                        </svg>
                        <template v-if="currentKey == 4">
                          <a style="color: #1570ef; font-size: 12px;" href="javascript:;">股票</a>
                          <CheckOutlined style="color: #1570ef;" />
                        </template>
                        <template v-else>
                          <a style="color: #000; font-size: 12px;" href="javascript:;">股票</a>
                        </template>
                      </a-space>
                    </a-menu-item>
                    <!-- <a-menu-item key="2">
                      <a-space size="small">
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="6" cy="6" r="4.5" fill="#2E90FA" stroke="#D1E9FF" stroke-width="3"></circle>
                        </svg>
                        <template v-if="currentKey == 2">
                          <a style="color: #1570ef; font-size: 12px;" href="javascript:;">知乎</a>
                          <CheckOutlined style="color: #1570ef;" />
                        </template>
                        <template v-else>
                          <a style="color: #000; font-size: 12px;" href="javascript:;">知乎</a>
                        </template>
                      </a-space>
                    </a-menu-item>
                    <a-menu-item key="3">
                      <a-space size="small">
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="6" cy="6" r="4.5" fill="#07C160" stroke="#cfe8cf" stroke-width="3"></circle>
                        </svg>
                        <template v-if="currentKey == 3">
                          <a style="color: #1570ef; font-size: 12px;" href="javascript:;">公众号</a>
                          <CheckOutlined style="color: #1570ef;" />
                        </template>
                        <template v-else>
                          <a style="color: #000; font-size: 12px;" href="javascript:;">公众号</a>
                        </template>
                      </a-space>
                    </a-menu-item>
                    <a-menu-item key="4">
                      <a-space size="small">
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="6" cy="6" r="4.5" fill="#cb1c1d" stroke="#fae8e9" stroke-width="3"></circle>
                        </svg>
                        <template v-if="currentKey == 4">
                          <a style="color: #1570ef; font-size: 12px;" href="javascript:;">人民网</a>
                          <CheckOutlined style="color: #1570ef;" />
                        </template>
                        <template v-else>
                          <a style="color: #000; font-size: 12px;" href="javascript:;">人民网</a>
                        </template>
                      </a-space>
                    </a-menu-item>
                    <a-menu-item key="5">
                      <a-space size="small">
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="6" cy="6" r="4.5" fill="#cb1c1d" stroke="#fae8e9" stroke-width="3"></circle>
                        </svg>
                        <template v-if="currentKey == 5">
                          <a style="color: #1570ef; font-size: 12px;" href="javascript:;">微博</a>
                          <CheckOutlined style="color: #1570ef;" />
                        </template>
                        <template v-else>
                          <a style="color: #000; font-size: 12px;" href="javascript:;">微博</a>
                        </template>
                      </a-space>
                    </a-menu-item>
                    <a-menu-item key="6">
                      <a-space size="small">
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="6" cy="6" r="4.5" fill="#cb1c1d" stroke="#fae8e9" stroke-width="3"></circle>
                        </svg>
                        <template v-if="currentKey == 6">
                          <a style="color: #1570ef; font-size: 12px;" href="javascript:;">中国证券网</a>
                          <CheckOutlined style="color: #1570ef;" />
                        </template>
                        <template v-else>
                          <a style="color: #000; font-size: 12px;" href="javascript:;">中国证券网</a>
                        </template>
                      </a-space>
                    </a-menu-item> -->
                  </a-menu>
                </template>
              </a-dropdown>

              <!--模型选择-->
              <a-dropdown arrow :trigger="['click']" :overlayStyle="{ maxHeight: '200px', overflow: 'auto', fontSize: '12px' }">
                <a-tooltip title="设置大模型" :overlayStyle="{ fontSize: '12px' }">
                  <a-button size="middle" shape="round" type="text" style="display: flex; align-items: center; color: rgba(0, 0, 0, .6);">
                    <CodeSandboxOutlined style="font-size: 15px;" />
                    <span style="font-size: 12px; padding: 0px;">Qwen/Qwen2.5-7B-Instruct</span>
                  </a-button>
                </a-tooltip>

                <template #overlay>
                  <a-menu @click="handleMenuClick">
                    <!-- <a-menu-item style="font-size: 13px;" key="1">01-ai/Yi-1.5-6B-Chat</a-menu-item>
                    <a-menu-item style="font-size: 13px;" key="2">01-ai/Yi-1.5-9B-Chat-16K</a-menu-item>
                    <a-menu-item style="font-size: 13px;" key="3">google/gemma-2-9b-it</a-menu-item>
                    <a-menu-item style="font-size: 13px;" key="4">meta-llama/Meta-Llama-3-1-8B-Instruct</a-menu-item>
                    <a-menu-item style="font-size: 13px;" key="5">meta-llama/Meta-Llama-3-8B-Instruct</a-menu-item>
                    <a-menu-item style="font-size: 13px;" key="6">Qwen/Qwen2-7B-Instruct</a-menu-item>
                    <a-menu-item style="font-size: 13px;" key="7">Qwen/Qwen2.5-7B-Instruct</a-menu-item>
                    <a-menu-item style="font-size: 13px;" key="8">Qwen/Qwen2.5-Coder-7B-Instruct</a-menu-item>
                    <a-menu-item style="font-size: 13px;" key="9">THUDM/glm-4-9b-chat</a-menu-item>
                    <a-menu-item style="font-size: 13px;" key="10">internlm/internlm2_5-7b-chat</a-menu-item> -->
                    
                    <a-menu-item style="font-size: 13px;" key="1">BAAI/bge-m3</a-menu-item>
                    <a-menu-item style="font-size: 13px;" key="2">BAAI/bge-reranker-v2-m3</a-menu-item>
                    <a-menu-item style="font-size: 13px;" key="3">Black-forest-labs/FLUX.1-dev</a-menu-item>
                    <a-menu-item style="font-size: 13px;" key="4">Black-forest-labs/FLUX.1-schnell</a-menu-item>
                    <a-menu-item style="font-size: 13px;" key="5">DianXin/DianXin-V1-Chat</a-menu-item>
                    <a-menu-item style="font-size: 13px;" key="6">Deepseek-ai/DeepSeek-V2.5</a-menu-item>
                    <a-menu-item style="font-size: 13px;" key="7">Internlm/internlm2_5-20b-chat</a-menu-item>
                    <a-menu-item style="font-size: 13px;" key="8">Internlm/internlm2_5-7b-chat</a-menu-item>
                    <a-menu-item style="font-size: 13px;" key="9">Meta-llama/Meta-Llama-3.1-405B-Instruct</a-menu-item>
                    <a-menu-item style="font-size: 13px;" key="10">Meta-llama/Meta-Llama-3.1-70B-Instruct</a-menu-item>
                    <a-menu-item style="font-size: 13px;" key="11">Meta-llama/Meta-Llama-3.1-8B-Instruct</a-menu-item>
                    <a-menu-item style="font-size: 13px;" key="12">Netease-youdao/bce-embedding-base_v1-Free</a-menu-item>
                    <a-menu-item style="font-size: 13px;" key="13">Netease-youdao/bce-reranker-base_v1-Free</a-menu-item>
                    <a-menu-item style="font-size: 13px;" key="14">Pro/black-forest-labs/FLUX.1-schnell</a-menu-item>
                    <a-menu-item style="font-size: 13px;" key="15">Qwen/Qwen2-1.5B-Instruct</a-menu-item>
                    <a-menu-item style="font-size: 13px;" key="16">Qwen/Qwen2-7B-Instruct</a-menu-item>
                    <a-menu-item style="font-size: 13px;" key="17">Qwen2.5-14B-Instruct</a-menu-item>
                    <a-menu-item style="font-size: 13px;" key="18">Qwen2.5-32B-Instruct</a-menu-item>
                    <a-menu-item style="font-size: 13px;" key="19">Qwen2.5-72B-Instruct</a-menu-item>
                    <a-menu-item style="font-size: 13px;" key="20">Qwen2.5-7B-Instruct</a-menu-item>
                    <a-menu-item style="font-size: 13px;" key="21">THUDM/chatglm3-6b-Free</a-menu-item>
                    <a-menu-item style="font-size: 13px;" key="22">THUDM/glm-4-9b-chat-Free</a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>

              <!--ollama开关-->
              <!-- <a-space size="small" style="display: flex; align-items: center; gap: 4px; margin-left: 5px;">
                <img :src="require('@/assets/ollama-icon-32x32.png')" style=" width: 15px; height: 15px; vertical-align: middle;" />
                <a-switch checked="false" size="small"/>
              </a-space> -->

              </a-flex>

              <!--发送按钮-->
              <a-flex flex="1" justify="flex-end">
              <a-button v-if="!Submiting" @click="sendMessage" shape="circle" size="middle" style="font-size: 13px;" type="text" :disabled="SubmitButtonDisabled" :loading="Submiting" :icon="h(ArrowRightOutlined)" />
              <a-button v-else @click="stopStream" shape="circle" size="middle" type="primary"><div class="square-icon"></div></a-button>
              </a-flex>

            </a-flex>
          </a-flex>

          <!--切换条-->
          <a-segmented v-if="false" size="large" class="model-select-bar" v-model:value="value" :options="data" />

          <!--标签-->
          <a-flex class="tags" gap="small" justify="center"
            align="center" horizontal>
            <button class="tag-button" @click="handleTagClick">
              <!-- <StockOutlined style="padding-right: 2px;" /> -->
              <span>如何制定健身计划？</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" viewBox="0 0 12 12">
                <path stroke="#667085" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                  d="m3 9 6-6m0 0H5m4 0v4"></path>
              </svg>
            </button>
            <button class="tag-button" @click="handleTagClick">
              <FormOutlined style="padding-right: 2px;" />
              <span>写一篇关于GPT-4o的文章</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" viewBox="0 0 12 12">
                <path stroke="#667085" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                  d="m3 9 6-6m0 0H5m4 0v4"></path>
              </svg>
            </button>
            <button class="tag-button" @click="handleTagClick">
              <!-- <CloudOutlined style="padding-right: 2px;" /> -->
              <span>推荐一些高蛋白食物</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" viewBox="0 0 12 12">
                <path stroke="#667085" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                  d="m3 9 6-6m0 0H5m4 0v4"></path>
              </svg>
            </button>
            <button class="tag-button" @click="handleTagClick">
              <LinkOutlined style="padding-right: 2px;" />
              <span>阅读文章列出RAG的优劣</span>
              <span
                style="text-decoration: underline;">https://www.elastic.co/cn/what-is/retrieval-augmented-generation</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" viewBox="0 0 12 12">
                <path stroke="#667085" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                  d="m3 9 6-6m0 0H5m4 0v4"></path>
              </svg>
            </button>
            <button class="tag-button" @click="handleTagClick">
              <span>请问每天走多少步对健康最有益？</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" viewBox="0 0 12 12">
                <path stroke="#667085" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                  d="m3 9 6-6m0 0H5m4 0v4"></path>
              </svg>
            </button>
            <button class="tag-button" @click="handleTagClick">
              <span>有哪些健康的早餐食谱推荐？</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" viewBox="0 0 12 12">
                <path stroke="#667085" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                  d="m3 9 6-6m0 0H5m4 0v4"></path>
              </svg>
            </button>
            <button class="tag-button" @click="handleTagClick">
              <span>如何利用AI技术提升企业竞争力？</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" viewBox="0 0 12 12">
                <path stroke="#667085" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                  d="m3 9 6-6m0 0H5m4 0v4"></path>
              </svg>
            </button>
            <button class="tag-button" @click="handleTagClick">
              <span>翻译成中文</span>
              <span style="text-decoration: underline;">https://blogs.nvidia.com/blog/what-is-retrieval-augmented-generation/</span>
            </button>
          </a-flex>

          </a-flex><!--end-->
        </a-flex>
      </a-layout-content>
      <a-layout-footer style="text-align: center; font-size: 13px; padding: 24px 0; background-color: white;">
        MIYO.AI © 2024 Created by MIYO.AI - Beta version
      </a-layout-footer>
    </a-layout>
  </a-layout>
  
</template>
<script setup>
import { reactive, ref, h } from 'vue';
import {
  DownOutlined,
  PlusOutlined,
  ArrowRightOutlined,
  BulbFilled,
  SearchOutlined,
  LoadingOutlined,
  DesktopOutlined,
  UserOutlined,
  TeamOutlined,
  FileOutlined,
  PieChartOutlined,
  FileTextOutlined,
  FilePdfOutlined,
  FileWordOutlined,
  EllipsisOutlined,
  CodeSandboxOutlined,
  CheckOutlined,
  LinkOutlined,
  FormOutlined,
  StockOutlined,
  CloudOutlined,
  BorderOutlined
} from '@ant-design/icons-vue';
import { useRoute, useRouter } from 'vue-router';
const route = useRoute(); // 获取路由信息
const router = useRouter(); // 获取路由实例、操作
import { message } from 'ant-design-vue';
// 用户输入
const Message = ref("");

// 提交状态
const Submiting = ref(false)

const SubmitButtonDisabled = ref(true)

let selectedItem = {text: '全网', fill: '#F79009', stroke: '#FEF0C7'}
let currentKey = 1;

const onMenuClick = ({ key}) => {
  if(key == '4') {
    message.info({
      content: () => '敬请期待',
    });
    return;
  }

  currentKey = parseInt(key);
  switch (key) {
    case '1':
      selectedItem = { text: '全网', fill: '#F79009', stroke: '#FEF0C7' };
      break;
    case '2':
      selectedItem = { text: '谷歌', fill: '#2E90FA', stroke: '#D1E9FF' };
      break;
    case '3':
      selectedItem = { text: '必应', fill: '#07C160', stroke: '#cfe8cf' };
      break;
    case '4':
      selectedItem = { text: '人民网', fill: '#cb1c1d', stroke: '#fae8e9' };
      break;
    case '5':
      selectedItem = { text: '微博', fill: '#cb1c1d', stroke: '#fae8e9' };
      break;
    case '6':
      selectedItem = { text: '中国证券网', fill: '#cb1c1d', stroke: '#fae8e9' };
      break;
    default:
      break;
  }
};

// 标签点击事件
const handleTagClick = function(e) { 
  if (Submiting.value)  return

  // 获取 button 内部的所有文本内容
  const spans = e.currentTarget.querySelectorAll('span');

  let textContent = '';
  spans.forEach(span => {
    textContent += span.textContent;
  });


  // 获取点击元素的文本内容
  // Message.value = e.target.innerText || e.target.textContent;
  Message.value = textContent;

  // 移除点击元素的样式
  e.target.classList.remove('active');

  // 阻止事件冒泡
  e.stopPropagation();
  sendMessage(Message.value)
}

// 输入框改变事件
const TAChangeEvent = function(e) {
  if (e.target.value) {
    SubmitButtonDisabled.value = false
  }else{
    SubmitButtonDisabled.value = true
  }
}

const handleMenuClick = ({ key }) => {
  message.info({
    content: () => '敬请期待',
    // style: {
    //   marginTop: '20vh',
    // },
  });
}

const TAKeyDownEvent = function(e) {
  // console.log(e.keyCode, e.shiftKey)

  // 检查是否按下Enter键并且Shift键未被按下
  if (e.keyCode === 13 && !e.shiftKey) {
    e.preventDefault(); // 阻止默认的换行行为
    sendMessage();
  } else if (e.keyCode === 13 && e.shiftKey) {
    // 在这里添加换行逻辑，比如在文本末尾添加换行符
    // Message.value += '';
  }
}

// 消息发送事件
const sendMessage = function(){
  console.log("sendMessage");

  if(!Message.value) return;

  if (Message.value) {
    // 跳转到 /so 并带上查询参数 q
    router.push({ path: '/so', query: { q: Message.value, type: currentKey } });
  }
  
}

const data = reactive(['搜索', '代码', '对话']);
const value = ref(data[0]);
</script>
<style scoped>


::v-deep(.model-select-bar .ant-segmented-group .ant-segmented-item-label) {
  font-size: 13px;
}

.message .avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('.././assets/user-face.png');
  background-size: cover; /* 或者使用 background-size: contain; */
  background-repeat: repeat;
  margin-right: 10px;
}

.message .text {
  background-color: #f6f8fa;
  padding: 0 10px;
  margin: 0;
  border-radius: 10px;
  width: 100%;

  line-height: 1.6; 
  letter-spacing: 0.5px; 
  font-size: 15px;
  color: #333;
  word-spacing: 1px;
}

.tags {
  padding: 15px 0; 
  max-width: 760px; 
  flex-wrap: wrap; 
}

.tags button span{
  font-size: 12px;
}


/* ::v-deep(.message .text p){
  padding: 0 10px 10px;
  margin: 0;
} */

::v-deep(.message .text pre) {
  position: relative;
}

::v-deep(.message.user .text) {
  padding: 1em 0.6em;
}

::v-deep(.message .text pre button.hljs-copy-button) {
    visibility: hidden;
    opacity: 0; 
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: transparent;
    color: #1677ff;
    border: none;
    border-radius: 4px;
    padding: 4px 8px;
    cursor: pointer;
    font-size: 0.8rem;
    transition: opacity 0.3s, visibility 0.3s;
}

::v-deep(.message .text pre:hover button.hljs-copy-button) {
    visibility: visible;
    opacity: 1;
}

::v-deep(.message .text table) {
    border-collapse: collapse;
    width: 100%;
    overflow: auto;
    word-break: normal;
    word-break: keep-all;
    font-family: Arial, sans-serif;
    margin-bottom: 10px;
}

::v-deep(.message .text table th, table td) {
  background-color: #f6f8fa;
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

::v-deep(.message .text table th) {
  background-color: #f8f9fa;
  color: #333;
  font-weight: bold;
}

::v-deep(.message .text table tr:nth-child(even)) {
  background-color: #f8f9fa;
}

::v-deep(.message .text table tr:hover) {
  background-color: #f1f1f1;
}

::v-deep(.message .text table tbody tr td) {
  border: 1px solid #ddd; /* 为 tbody 内容设置边框 */
  line-height: 2
}

/* .message.user .text {
  background-color: #007bff;
  color: #fff;
} */

.chat-input {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ddd;
}

.chat-input input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
  margin-right: 10px;
}

.chat-input button {
  padding: 10px 15px;
  border: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 20px;
  cursor: pointer;
}

::v-deep(.ant-comment-content-detail .conten-box) {
  line-height: 1.8
}


::v-deep(pre code.hljs) {
/* 使用::v-deep伪类选择器，用于深度作用于.vue文件中的样式 */

/* 设置水平方向上允许滚动 */
/* overflow-x: auto; */

/* 垂直方向上隐藏内容（如果需要可以改为auto或visible） */
/* overflow-y: hidden; */

/* 设置字体大小 */
font-size: .9em;

/* 设置宽度为100% */
width: 100%;

/* 设置最大宽度为600px */
max-width: 600px;

white-space: pre-wrap; /* 自动换行 */
word-break: break-all; /* 在长单词或URL内部进行换行 */

/* 设置背景颜色 */
background-color: #00152a;

/* 添加阴影效果 */
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.8);

/* 设置字体 */
/* font-family: "Fira Code", "Fira Mono", Menlo, Consolas, "DejaVu Sans Mono", monospace; */
font-family: "Fira Code", "Fira Mono", Menlo, Consolas, "DejaVu Sans Mono", "Source Code Pro", "Inconsolata", "Roboto Mono", "Ubuntu Mono", "Hack", "Courier New", "Lucida Console", monospace;


/* 设置文本方向为从左到右 */
direction: ltr;

/* 设置文本对齐方式为左对齐 */
text-align: left;

/* 设置单词间的间距为默认值 */
word-spacing: normal;

/* 设置单词换行方式为默认值 */
word-break: normal;

/* 设置行高为1.5倍 */
line-height: 1.5;

/* 设置制表符大小 */
tab-size: 2;

/* 禁用自动断词 */
hyphens: none;

/* 设置内边距 */
padding: 1em;

/* 设置外边距 */
margin: 0.5em 0px;

/* 设置边框圆角 */
border-radius: 0.3em;

}

::v-deep(pre code.hljs .hljs-subst) {
  color: #fff;
}

/* .copy-button {
  position: absolute;
  top: 4px;
  right: 4px;
  padding: 2px 8px;
  font-size: 12px;
  background: #4CAF50;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.copy-button:hover {
  background: #45a049;
}

pre {
  position: relative;
  padding: 16px;
} */

/* .tag-container {
    display: flex;
    align-items: center;
    max-width: 730px;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
} */

.tag-button {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    border-radius: 20px;
    background-color: #ffffff;
    color: #333;
    text-decoration: none;
    font-size: 14px;
    border: 1px solid #e0e0e0;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
}

.tag-button span {
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.tag-button:hover {
    background-color: #f0f0f0;
    border-color: #ccc;
}

.tag-button svg {
    margin-left: 8px;
    fill: none;
    stroke: #667085;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5;
}

::v-deep(div.source-txt-line) {
  padding: 15px 0 10px 10px;
  display: flex;
  justify-content: start;
  align-items: center;
}

::v-deep(div.source-txt-line span) {
  margin-left: 5px;
}

::v-deep(ol.source) {
    list-style-type: none; /* 去除列表样式 */
    counter-reset: li-counter; /* 重置计数器 */
    margin: 0;
    padding: 0 10px 5px;
    font-size: 14px;
}

::v-deep(ol.source li span) {
  font-size: 14px;
  color: #000;
}

::v-deep(ol.source li) {
    counter-increment: li-counter; /* 每个li元素增加计数器 */
    margin-bottom: 5px; 
    /* line-height: 2;  */

    opacity: 0;
    animation: fadeIn 1s forwards;

    @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
            animation-delay: 0.5s * ($i - 1);
        }
    }
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

/* 定义加载动画 */
@keyframes loadingss {
  0% { content: "整理输出中."; }
  33% { content: "整理输出中.."; }
  66% { content: "整理输出中..."; }
}

/* 应用加载动画到元素 */
::v-deep(.loadingss::after) {
  content: "整理输出中.";
  animation: loadingss 1.5s infinite;
}

::v-deep(ol.source li a) {
    color: var(--Gary-Group1-500, #667085); /* 设置链接颜色为黑色 */
    text-decoration: none; /* 去除下划线 */
    display: inline-block; /* 将a元素转换为块级元素 */
    transition: color 0.3s; /* 添加过渡效果 */

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 418px;
    display: inline-block;
    vertical-align: top;
}

::v-deep(ol.source li:hover a) {
    text-decoration: underline; /* 鼠标悬停时显示下划线 */
    color: #000; /* 设置文字颜色为黑色 */
    /* font-weight: bold; */
}

::v-deep(ol.source li::before) {
    content: counter(li-counter) ". "; /* 添加计数器内容 */
    /* font-weight: bold;  */
    color: var(--Gary-Group1-500, #667085); /* 设置计数器颜色 */
    margin-right: 5px; /* 添加一些右边距 */
}

/* 
.subscript {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    line-height: 1.5em;
    text-align: center;
    border-radius: 50%;
    background-color: #eee;
    color: #333;
    font-size: 0.75em;
    vertical-align: middle;
  }

.footnote-circle {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #ccc;
  text-align: center;
  line-height: 24px;
  text-decoration: none;
  color: black;
  
} */

::v-deep(.circle) {
    /* width: 16px;
    height: 16px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    background-color:#d0d5dd;
    font-size: 9px;
    display: inline-flex;
    cursor: pointer; */

    display: inline-block; /* 设置为内联块级元素 */
    width: 16px; /* 设置圆的宽度 */
    height: 16px; /* 设置圆的高度 */
    line-height: 16px; /* 设置行高与圆的高度一致，使文本垂直居中 */
    font-size: 9px;
    text-align: center; /* 文本水平居中 */
    border-radius: 50%; /* 设置为圆形 */
    background-color: #d0d5dd; /* 设置圆的背景色 */
    color: #000; /* 设置文本颜色 */
    margin-left: 4px; /* 设置左边距，调整与文本的间距 */
    cursor: pointer;
}

::v-deep(.ant-skeleton-header) {
  padding-inline-end: 10px;
}

::v-deep(.ant-skeleton-content .ant-skeleton-title) {
  margin-top: 7px;
}

::v-deep(.ant-skeleton-header .ant-skeleton-avatar) {
  width: 30px;
  height: 30px;
}

::v-deep(.ant-list-items .ant-list-item) {
  padding: 0;
}

::v-deep(.ant-comment-content-detail) {
  font-size: 15px;
}

/* css穿透::v-deep */
::v-deep(.ant-list-item-meta-title) {
  padding: 0;;
  margin: 5px;
}

::v-deep(.ant-comment-avatar img) {
  width: 28px;
  height: 28px;
}

::v-deep(.ant-comment-content-author) {
  padding-top: 5px;
}

::v-deep(.ant-comment-content-author > span) {
  color: #000;
  font-weight: bold;
  font-size: 16px;
}

.logo {
  display: block;
  /* 确保是块级元素 */
  height: 40px;
  width: 40px;
  /* background: rgba(255, 255, 255, 0.3); */
  background-image: url('http://localhost:8080/logo.png');
  background-size: contain;
  /* 调整图像尺寸以适应容器 */
  background-repeat: no-repeat;
  /* 防止图像重复 */
  margin: 20px auto 0 auto;
  filter: blur(2px);
}

.logo-text {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  margin-left: 10px;
  align-items: center;
}

/* 
.search-box {
  border-radius: 10px;
  border: 2px solid #767676;
  min-height: 143px;
  width: 100%;
  max-width: 650px;

  padding: 5px 0 0 5px;
} */

.search-box {
  border-radius: 10px;
  border: 2px solid rgba(255, 255, 255, 0.6); /* 透明水晶效果的边框 */
  min-height: 143px;
  width: 100%;
  max-width: 650px;
  background: rgba(255, 255, 255, 0.2); /* 透明背景 */
  backdrop-filter: blur(10px); /* 背景模糊，提升水晶感 */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), inset 0 1px 3px rgba(255, 255, 255, 0.4); /* 外部阴影+内阴影，增加立体感 */
  transition: box-shadow 0.3s ease, border 0.3s ease; /* 过渡效果 */
}

/* 增加鼠标悬停效果 */
.search-box:hover {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2), inset 0 2px 4px rgba(255, 255, 255, 0.6); /* 悬停时加强的阴影效果 */
  border: 2px solid rgba(255, 255, 255, 0.8); /* 悬停时边框更亮 */
}

.search-box-textarea {
  cursor: text;
  border-radius: 15px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column;
  flex-flow: column;
  padding: 5px 5px 0;
  -ms-flex-align: stretch;
  align-items: stretch;
  box-sizing: border-box;
  position: relative;
  width: 100%;
}

#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}

[data-theme='dark'] .site-layout .site-layout-background {
  background: #141414;
}
</style>