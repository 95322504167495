import { createRouter, createWebHistory } from 'vue-router'
import PageLayout from '@/views/PageLayout'
import Home from '@/views/Home'
import So from '@/views/So'
import { HomeOutlined } from '@ant-design/icons-vue'

const routes = [
    { // 首页路由
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            icon: HomeOutlined,
            title: '让信息探索更简单'
        },
    },
    { // 搜索页路由
        path: '/so',
        name: 'so',
        component: So,
        // meta: {
        //     title: '搜索'
        // }
    },
    // { // 登录页路由
    //     path: '/login',
    //     name: 'login',
    //     component: () => import('../views/user/Login.vue'),
    //     meta: {
    //         title: '登录'
    //     },
    // },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    // 设置页面标题
    if(to.meta.title){
        document.title = "米柚AI - " + to.meta.title;
    }

    // if (to.path !== '/login' && !sessionStorage.getItem('token')) {
    //     next('/login')
    // }
    next()
})

export default router
